import { constants } from '@arbitrum/sdk'
import { NativeCurrencyBase } from '../hooks/useNativeCurrency'
import { ChainWithRpcUrl } from './networks'

export type NetworkType =
  | 'Ethereum'
  | 'Rollup'
  | 'AnyTrust'
  | 'Ethereum Testnet'
  | 'Arbitrum Testnet'

export type BridgeUiConfig = {
  color: `#${string}`
  network: {
    name: string
    logo: string
    description?: string
  }
  nativeTokenData?: NativeCurrencyBase
}

type OrbitChainConfig = ChainWithRpcUrl & { bridgeUiConfig: BridgeUiConfig }

export const orbitMainnets: {
  [key: number]: OrbitChainConfig
} = {
  1200: {
    chainID: 1200,
    confirmPeriodBlocks: 150,
    ethBridge: {
      bridge: '0x6a075fbDFEd3d18bCdc62668fE0f02c639144ed8',
      inbox: '0x2b25AAC8ef6F1a405E824C257a349b79c79Ed45c',
      outbox: '0x637EbFe011F5286D800D8D975eBF09547157b505',
      rollup: '0xfEE1e4386fee1E337178ce0814e7959b9E67b5F5',
      sequencerInbox: '0x43c51b92bA8b9e89484D5eFa4a87Fa7526793b04'
    },
    rpcUrl: 'https://mainnet-rpc.cuckoo.network',
    explorerUrl: 'https://mainnet-scan.cuckoo.network',
    isCustom: true,
    name: 'Cuckoo Chain',
    partnerChainID: 42161,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 900000,
    blockTime: 0.25,
    nativeToken: '0x9e3C88E95811DB0db93B675f9985faFf3972C615',
    isArbitrum: true,
    tokenBridge: {
      l1CustomGateway: '0xd9750c8B9bb9dff8D31B5772778e792A324aEe72',
      l1ERC20Gateway: '0xb37dBD065B74a4b5552690EEe6773E971Ca92B59',
      l1GatewayRouter: '0x8A7d8CB95C93B536131FF52C936C4e9740275cD3',
      l1MultiCall: '0x90B02D9F861017844F30dFbdF725b6aa84E63822',
      l1ProxyAdmin: '0x0000000000000000000000000000000000000000',
      l1Weth: '0x0000000000000000000000000000000000000000',
      l1WethGateway: '0x0000000000000000000000000000000000000000',
      l2CustomGateway: '0x37D6cc4DC18690f3Adfca9CFeeAd4c23D69a16b9',
      l2ERC20Gateway: '0x2634aF1CB99c717cf1b350A6e483441B2f6A7596',
      l2GatewayRouter: '0x8557c11f01b8f9B9f64f0AaF650A28AcA063650f',
      l2Multicall: '0x97025c1D0280a8b3aAE1970e06835ce0f3476246',
      l2ProxyAdmin: '0xEd046bEFF0a4B7c60528BD728462410316b9F6af',
      l2Weth: '0x0000000000000000000000000000000000000000',
      l2WethGateway: '0x0000000000000000000000000000000000000000'
    },
    bridgeUiConfig: {
      color: '#3b82f6',
      network: {
        name: 'Cuckoo Chain',
        logo: 'https://cuckoo.network/img/favicon.svg',
        description: 'The testnet for Cuckoo AI chain.'
      },
      nativeTokenData: {
        name: 'CAI',
        symbol: 'CAI',
        decimals: 18,
        logoUrl: 'https://cuckoo.network/img/favicon.svg'
      }
    }
  }
}

export const orbitTestnets: { [key in number]: OrbitChainConfig } = {
  1210: {
    chainID: 1210,
    confirmPeriodBlocks: 150,
    ethBridge: {
      bridge: '0x84c599703Fd5d3031c2AaF0a32c3a89bB64Ad89A',
      inbox: '0x31Ec68f7B326a45D8CDC3644569230A322bA9C50',
      outbox: '0x9c2C5484Da1e6669d0034A918A4E6215E2c3681e',
      rollup: '0xA5f8EA23030F2cDE95f8ffeb56315BaF86f2E64c',
      sequencerInbox: '0x904b97f741BFD8d00c7D7644E05fFAF71985b5c1'
    },
    rpcUrl: 'https://testnet-rpc.cuckoo.network',
    explorerUrl: 'https://testnet-scan.cuckoo.network',
    isCustom: true,
    name: 'Cuckoo Sepolia',
    partnerChainID: 421614,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 900000,
    blockTime: 0.25,
    nativeToken: '0x598157389C38b826701Ecd1e5531D705cf8090F9',
    isArbitrum: true,
    tokenBridge: {
      l1CustomGateway: '0xF791664BC91D0108B6e33AAA8CFF30cf7dF4D96F',
      l1ERC20Gateway: '0x492F45419FB742caa1612D9A4eC36d5F8446aDa2',
      l1GatewayRouter: '0x6355716Cfa12CeBc1E852E02901B6d4eE6521fCF',
      l1MultiCall: '0xce1CAd780c529e66e3aa6D952a1ED9A6447791c1',
      l1ProxyAdmin: '0x0000000000000000000000000000000000000000',
      l1Weth: '0x0000000000000000000000000000000000000000',
      l1WethGateway: '0x0000000000000000000000000000000000000000',
      l2CustomGateway: '0x6B177C4285E0888bE8B6A965927D7Cd859616716',
      l2ERC20Gateway: '0xeF822f4E2724D41515a5Fe6cb2F741D6eAdA9E83',
      l2GatewayRouter: '0x3a38475c3d3C6e70280e46EFd952c30D315A81DB',
      l2Multicall: '0x2D9Dd4Dad2FeB0b27aDf156f85e0BeaBf4295A6e',
      l2ProxyAdmin: '0xC07E90c19b7824A4B4D4D7A9D567181a366D7ece',
      l2Weth: '0x0000000000000000000000000000000000000000',
      l2WethGateway: '0x0000000000000000000000000000000000000000'
    },
    bridgeUiConfig: {
      color: '#3b82f6',
      network: {
        name: 'Cuckoo Sepolia Testnet',
        logo: 'https://cuckoo.network/img/favicon.svg',
        description: 'The testnet for Cuckoo AI chain.'
      },
      nativeTokenData: {
        name: 'CAI',
        symbol: 'CAI',
        decimals: 18,
        logoUrl: 'https://cuckoo.network/img/favicon.svg'
      }
    }
  }
}

export const orbitChains = { ...orbitMainnets, ...orbitTestnets }

export function getOrbitChains(
  {
    mainnet,
    testnet
  }: {
    mainnet: boolean
    testnet: boolean
  } = { mainnet: true, testnet: true }
): OrbitChainConfig[] {
  const mainnetChains = mainnet ? Object.values(orbitMainnets) : []
  const testnetChains = testnet ? Object.values(orbitTestnets) : []

  return [...mainnetChains, ...testnetChains]
}
